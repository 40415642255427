<template>
	<!-- container -->
	<div>
		<!-- titArea -->
		<div class="titArea">
			<div class="inner">
				<h2 class="txtL">
					메모등록
				</h2>
				<a @click="onMove()" class="btnBack">메모 목록으로 이동</a>
			</div>
		</div>
		<!--// titArea -->
		<!-- boxWhite -->
		<div class="boxWhite">
			<div class="inner">
				<!-- inputTable -->
				<div class="inputTable v2">
					<table>
						<caption>
							메모내용, 실별 오브제, 이미지 항목으로 구성된 메모수정 표입니다.
						</caption>
						<colgroup>
							<col style="width:110px" />
							<col style="width:auto" />
						</colgroup>
						<tbody>
							<tr class="fN">
								<th scope="row">메모내용 <span>*</span></th>
								<td>
									<textarea
										title="메모내용"
										placeholder="메모내용을 입력하세요."
										v-model="content"
										maxlength="1000"
										style="min-height: 150px;"
									></textarea>
								</td>
							</tr>
							<tr>
								<th scope="row">사용 위치</th>
								<td>
									<!-- attaArea -->
									<div class="attaArea">
										<p v-for="(location, index) in locations" :key="index">
											<span
												>{{ location.floor ? location.floor.name : '' }}
												{{ location.room ? ' &gt; ' + location.room.name : '' }}
												{{ location.objectPin ? ' &gt; ' + location.objectPin.objectName : '' }}</span
											><a class="btnDel" @click="deleteLocation(index)">삭제</a>
										</p>
									</div>
									<!--// attaArea -->
								</td>
							</tr>
							<tr>
								<th scope="row">위치 등록</th>
								<td>
									<div class="btnBox">
										<a class="btn btnWhite memoBtn" @click="onOpenLocation()">추가하기</a>
									</div>
								</td>
							</tr>
							<tr>
								<th scope="row">스케줄</th>
								<td>
									<div class="float">
										<span class="checkbox">
											<input
												type="checkbox"
												id="chk01"
												:checked="schedule != null"
												@click.prevent.stop="onOpenSchedule"
												style="cursor:pointer"
											/>
											<label for="chk01">{{ schedule ? schedule.name : null }}</label>
										</span>
										<span class="flex">{{
											schedule
												? `${$moment(schedule.beginDate).format('YYYY년 MM월 DD일')} ~ 
												${$moment(schedule.endDate).format('YYYY년 MM월 DD일')}`
												: null
										}}</span>
										<button type="button" class="btn ss" v-if="schedule" @click.prevent="onModifySchedule">수정</button>
									</div>
								</td>
							</tr>
							<tr>
								<th scope="row">프로젝트</th>
								<td>
									<span class="checkbox">
										<input type="checkbox" id="chk01" @click="onOpenProject" :checked="checkedValuesProject" />

										<label for="chk01"></label>
									</span>
									<span class="checkbox" v-if="checkedValuesProject">
										<input type="checkbox" id="chk02" @click="onOptionValueChk" :checked="checkedOptionValues" />
										<label for="chk02">전체</label>
									</span>

									<div class="flex" v-if="checkedValuesProject">
										<select title="선택" v-if="checkedOptionValues == true" v-model="projectId">
											<option :value="null">미선택</option>
											<option v-for="project in projects" :value="project.id" :key="project.id">
												{{ project.name + `  [${project.beginDate} ~ ${project.endDate}]` }}
											</option>
										</select>

										<select title="선택" v-if="checkedOptionValues == false" v-model="projectId">
											<option :value="null">미선택</option>
											<option v-for="project in projectsOrder" :value="project.id" :key="project.id">
												{{ project.name + `  [${project.beginDate} ~ ${project.endDate}]` }}
											</option>
										</select>

										<button type="button" class="btn ss" @click="onPrev()">신규</button>
									</div>
								</td>
							</tr>
							<tr>
								<th scope="row" class="mNone">이미지</th>
								<td>
									<!-- file -->
									<div class="file">
										<input
											class="btn btnWhite"
											type="file"
											title="이미지 업로드"
											required="required"
											id="image"
											@change="onImageUpload"
											accept="image/*"
											multiple
										/>
										<span>이미지 업로드</span>
									</div>
									<span class="checkbox" v-if="checkedVal()">
										<input type="checkbox" id="chk" v-model="checkedValues" />
										<label for="chk01">원본으로 올리기</label>
									</span>
									<!--// file -->
									<!-- imgFileList -->
									<ul class="imgFileList v2">
										<li v-for="(image, index) in images" :key="index">
											<div class="imgArea"><img :src="imagesPreview[index]" alt="" /></div>
											<a @click="onImageDelete(index)" class="btnDel">삭제</a>
										</li>
									</ul>
									<!--// imgFileList -->
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<!--// inputTable -->
				<!-- inputTable -->
				<div class="inputTable">
					<table>
						<caption>
							첨부파일 항목으로 구성된 메모수정 표입니다.
						</caption>
						<colgroup>
							<col style="width:110px" />
							<col style="width:auto" />
						</colgroup>
						<tbody>
							<tr>
								<th scope="row" class="mNone">첨부파일</th>
								<td>
									<!-- attaArea -->
									<div class="attaArea">
										<p v-for="(file, index) in files" :key="index">
											<a>{{ filesPreview[index] }}</a
											><a @click="onFileDelete(index)" class="btnDel">삭제</a>
										</p>
									</div>
									<!--// attaArea -->
									<!-- file -->
									<div class="file">
										<input
											type="file"
											title="첨부파일 업로드"
											required="required"
											id="file"
											@change="onFileUpload"
											multiple
										/>
										<span>첨부파일 업로드</span>
									</div>
									<!--// file -->
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<!--// inputTable -->
			</div>
		</div>
		<!--// boxWhite -->
		<!-- inner -->
		<div class="inner">
			<!-- btnBottom -->
			<div class="btnBottom">
				<button type="button" class="btn" @click="onUpload()" :disabled="!saveBtn">저장하기</button>
			</div>
			<!--// btnBottom -->
		</div>
		<!--// inner -->
		<PopUpLocation
			ref="popUpLocation"
			@selectLocation="getLocation"
			:propsBuildingId="buildingId"
			:propsFloorId="floorId"
			:propsRoomId="roomId"
			:propsObjectPinId="objectPinId"
		/>
		<PopUpSchedule ref="popUpSchedule" @setSchedule="getSchedule" />
	</div>
	<!--// container -->
</template>
<script>
import memo from '@/api/memo';
import file from '@/api/file';
import floor from '@/api/floor';
import room from '@/api/room';
import objectPin from '@/api/objectpin';
import PopUpLocation from '../popup/popUpLocation';
import PopUpSchedule from '../popup/popUpSchedule';
import project from '../../api/project';
import { showSpinner, hideSpinner, hideMenu, overFileSize } from '@/utils/cmm.js';
import { isImage } from '@/utils/cmm.js';

export default {
	components: { PopUpLocation, PopUpSchedule },
	created() {
		if (!this.buildingId) {
			this.$router.go(-1);
			return false;
		}
		hideMenu();
		if (this.$route.params.memoDetailId != null) {
			this.memoDetailId = this.$route.params.memoDetailId;
		}
		if (!this.$route.params.locations) {
			this.getDefaultLocation();
		}

		this.onGetProject();
	},
	computed: {
		saveBtn: {
			get() {
				return this.content.length > 0;
			},
			set() {},
		},
	},

	data() {
		return {
			buildingId: this.$route.params.buildingId,
			floorId: this.$route.params.floorId,
			roomId: this.$route.params.roomId,
			objectPinId: this.$route.params.objectPinId,
			content: '',
			images: [],
			imagesPreview: [],
			files: [],
			filesPreview: [],
			objectPinList: [],
			roomName: null,
			memoDetailId: null,
			checkedValues: false,
			checkedOptionValues: false,
			locations: this.$route.params.locations ? this.$route.params.locations : [],
			schedule: null,
			checkedValuesProject: this.$route.params.projectId ? true : null,
			projects: [],
			projectsOrder: [],
			projectRes: null,
			projectId: this.$route.params.projectId ? this.$route.params.projectId : null,
			projectIdTemp: this.$route.params.projectId ? this.$route.params.projectId : null,
			shapeType: null,
		};
	},
	methods: {
		async getDefaultLocation() {
			let location = {};
			if (!this.floorId) return;

			if (this.floorId) {
				location.floor = await floor.getOne(this.floorId);
			}
			if (this.roomId) {
				location.room = await room.getOne(this.roomId);
				this.shapeType = location.room.shapeType;
			}
			if (this.objectPinId) {
				location.objectPin = await objectPin.getOne(this.objectPinId);
			}
			this.locations.push(location);
		},
		async onImageUpload({ target }) {
			if (!(target && target.files && target.files.length)) return;
			const files = [];
			files.push.apply(files, target.files);
			document.getElementById('image').value = '';
			for (let i in files) {
				if (!isImage(files[i])) {
					return this.$alert(`이미지는 png, jpg, jpeg, bmp의 확장자 파일만 올려주세요.`);
				}
			}
			if (overFileSize(this.images, files, 0)) {
				return this.$alert(`이미지 파일의 총합은 100MB를 초과할 수 없습니다.`);
			}
			this.imagesPreview = [];
			this.images = this.images.concat(files);
			for (var index in this.images) {
				this.imagesPreview[index] = URL.createObjectURL(this.images[index]);
			}
		},
		async onImageDelete(id) {
			this.images.splice(id, 1);
			this.imagesPreview = [];
			for (var index in this.images) {
				this.imagesPreview[index] = URL.createObjectURL(this.images[index]);
			}
		},
		async onFileUpload({ target }) {
			if (!(target && target.files && target.files.length)) return;
			const files = [];
			files.push.apply(files, target.files);
			document.getElementById('file').value = '';
			if (overFileSize(this.images, files, 0)) {
				return this.$alert(`첨부 파일의 총합은 100MB를 초과할 수 없습니다.`);
			}
			this.filesPreview = [];
			this.files = this.files.concat(files);
			for (var index in this.files) {
				this.filesPreview[index] = this.files[index].name;
			}
		},
		async onFileDelete(id) {
			this.files.splice(id, 1);
			this.filesPreview = [];
			for (var index in this.files) {
				this.filesPreview[index] = this.files[index].name;
			}
		},
		async onUpload() {
			let locations = [];
			if (this.locations) {
				this.locations.forEach(l => {
					let location = {};
					location.floorId = null;
					if (l.floor) {
						location.floorId = l.floor.id;
					}
					location.roomId = null;
					if (l.room) {
						location.roomId = l.room.id;
					}
					location.objectPinId = null;
					if (l.objectPin) {
						location.objectPinId = l.objectPin.id;
					}
					locations.push(location);
				});
			}

			let params = {
				buildingId: this.buildingId,
				content: this.content,
				locations: locations,
				schedule: this.schedule,
				projectId: this.projectId,
			};

			if (this.floorId) {
				params.floorId = this.floorId;
			}
			if (this.roomId) {
				params.roomId = this.roomId;
			}
			if (this.objectPinId) {
				params.objectPinId = this.objectPinId;
			}

			try {
				showSpinner();
				if (this.images.length > 0) {
					let imageRes = await file.upload(this.images, this.checkedValues);
					params.imageInfos = imageRes.data;
				}

				if (this.files.length > 0) {
					let fileRes = await file.upload(this.files, true);
					params.fileInfos = fileRes.data;
				}

				await memo.create(params);
				// this.memoDetailId 메모가 정상적으로 등록되면 메모id를 날려 memoList 로 이동
				this.memoDetailId = null;

				this.onMove();
			} catch (ex) {
				return await this.$alert(`${ex.message}`);
			} finally {
				hideSpinner();
			}
		},
		async onMove() {
			if (this.$route.params.reqUrl == 'building') {
				this.$router.push({ name: 'buildingList' });
				return;
			}
			if (this.$route.params.reqUrl == 'buildingDetail') {
				this.$router.push({ name: 'buildingDetail', params: { id: this.buildingId } });
				return;
			}
			if (this.$route.params.reqUrl == 'floor') {
				this.$router.push({ name: 'floorDetail', params: { id: this.floorId } });
				return;
			}
			if (this.$route.params.reqUrl == 'room') {
				let res = await room.getOne(this.roomId);
				if (res.shapeType === 'all') {
					this.$router.push({ name: 'floorDetail', params: { id: this.floorId } });
					return;
				}
				this.$router.push({ name: 'roomDetail', params: { id: this.roomId } });
				return;
			}
			if (this.objectPinId) {
				if (this.shapeType == 'all') {
					this.$router.push({ name: 'floorDetail', params: { id: this.floorId } });
					return;
				}
				this.$router.push({
					name: 'memoList',
					params: {
						buildingId: this.buildingId,
						floorId: this.floorId,
						roomId: this.roomId,
						objectPinId: this.objectPinId,
					},
				});
			} else {
				this.$router.go(-1);
			}
		},
		onOpenLocation() {
			this.$refs.popUpLocation.onOpenLocation();
		},
		async getLocation(location) {
			let flag = true;
			for (let i = 0; i < this.locations.length; i++) {
				let lFloorId = null;
				if (this.locations[i].floor) {
					lFloorId = this.locations[i].floor.id;
				}
				let lRoomId = null;
				if (this.locations[i].room) {
					lRoomId = this.locations[i].room.id;
				}
				let lObjctPinId = null;
				if (this.locations[i].objectPin) {
					lObjctPinId = this.locations[i].objectPin.id;
				}

				let locationFloorId = null;
				if (location.floor) {
					locationFloorId = location.floor.id;
				}
				let locationRoomId = null;
				if (location.room) {
					locationRoomId = location.room.id;
				}
				let locationObjctPinId = null;
				if (location.objectPin) {
					locationObjctPinId = location.objectPin.id;
				}
				if (lFloorId == locationFloorId && lRoomId == locationRoomId && lObjctPinId == locationObjctPinId) {
					flag = false;
					break;
				}
			}
			if (flag == true) {
				this.locations.push(location);
			} else {
				this.$alert('동일한 위치가 이미 추가되어있습니다.');
			}
		},
		deleteLocation(index) {
			this.locations.splice(index, 1);
		},
		async onOpenSchedule() {
			if (this.schedule) {
				if (await this.$confirm('스케줄을 해제 하시겠습니까?', '스케줄 안내')) {
					this.schedule = null;
					return;
				}
			} else {
				this.$refs.popUpSchedule.onOpenSchedule(null);
			}
		},
		onModifySchedule() {
			this.$refs.popUpSchedule.onOpenSchedule(this.schedule);
		},
		getSchedule(schedule) {
			this.schedule = schedule;
		},
		setIntervalType(intervalType) {
			let interval = '매년';
			switch (intervalType) {
				case 'Y':
					interval = '매년';
					break;
				case 'M':
					interval = '매월';
					break;
				case 'W':
					interval = '매주';
					break;
				case 'D':
					interval = '지정일';
					break;
			}
			return interval;
		},
		async onPrev() {
			if (
				!(await this.$confirm(
					'현재 작업 중인 데이터가 취소됩니다.\r\n프로젝트로 이동 하시겠습니까?',
					'프로젝트 이동 안내',
				))
			) {
				return;
			}
			this.$router.push({ name: 'projectRegist', params: { buildingId: this.buildingId } });
		},
		async onGetProject() {
			let params = {
				buildingId: this.buildingId,
			};
			try {
				let res = await project.getProjectStartList(params);
				// total ( 등록된 projectList)가 없으면 미수행
				if (res.total != 0) {
					res.data.forEach(p => this.projects.push(p));

					res.data.forEach(p => {
						if (p.dateOrder == 1) this.projectsOrder.push(p);
					});
				}

				// 메모에 프로젝트가 등록되어 있으면 프로젝트 시작과 동시에 체크박스, 라벨 표시
				if (this.projectId) {
					this.checkedValuesProject = true;

					let proRes = await project.getOne(this.projectId);

					this.projectRes = proRes;
					if (proRes.dateOrder == 1) {
						this.checkedOptionValues = false;
					} else {
						this.checkedOptionValues = true;
					}
				}
			} catch (ex) {
				await this.$alert(`${ex.message}`);
			}
		},
		async onOpenProject() {
			// 프로젝트를 해제 또는 값이 없으면 모든값을 null fasle 처리한다. projectIdTemp를 미리 저장해 다시 체크할때 불러오게 했음.
			if (this.checkedValuesProject) {
				if (await this.$confirm('프로젝트를 해제 하시겠습니까?', '프로젝트 안내')) {
					this.checkedValuesProject = null;
					this.checkedOptionValues = false;
					this.projectId = null;
					return;
				}
			} else {
				// 프로젝트가 존재하면 프로젝트 라벨을 표시해준다.
				this.checkedValuesProject = true;

				// 오늘 날짜에 해당하는 프로젝트가 있는지 체크한다.
				if (this.projectRes && this.projectRes.dateOrder == 1) {
					// 프로젝트를 해제 하고 다시 체크 했을떄 자동으로 미리 저장한 값을 강제로 넣어준다.
					// checkedOptionValues가 false 이고, false이면 dateOrder값이 1인 리스트를 불러온다.
					this.projectId = this.projectIdTemp;
				} else {
					//(오늘 날짜에 해당하는 프로젝트가 없는 경우)
					//  프로젝트가 존재하지 않는 경우 체크
					if (this.projectRes == null) {
						this.checkedOptionValues = false;
						this.projectId = null;
					} else {
						// 프로젝트가 존재하고 지난 날짜의 프로젝트를 표시할 경우 true 처리 하여 전체보여주기 상태로 한다.
						this.checkedOptionValues = true;
						this.projectId = this.projectIdTemp;
					}
				}
			}
		},
		getFormatDate(date) {
			var year = date.getFullYear(); //yyyy
			var month = 1 + date.getMonth(); //M
			month = month >= 10 ? month : '0' + month; //month 두자리로 저장
			var day = date.getDate(); //d
			day = day >= 10 ? day : '0' + day; //day 두자리로 저장
			return year + '-' + month + '-' + day;
		},
		checkedVal() {
			// this.checkedValues = false;
			return !(Array.isArray(this.images) && this.images == 0);
		},
		onOpenSelect() {
			// console.log('dd');
		},
		onOptionValueChk() {
			// checkedOptionValues true 경우
			if (this.checkedOptionValues) {
				// 체크를 해제하므로 false 처리
				this.checkedOptionValues = false;

				// dateOrder (현재 날짜에 해당하는 프로젝트가 있나 체크)
				if (this.projectRes && this.projectRes.dateOrder == 1) {
					this.projectId = this.projectIdTemp;
				} else {
					this.projectId = null;
				}
			} else {
				this.checkedOptionValues = true;
				this.projectId = this.projectIdTemp;
			}
		},
	},
};
</script>

<style scoped></style>
